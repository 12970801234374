<template>
    <div class="activate-view ViewWrapper">
        <clv-head-meta :title="$t('Activate Account')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">
                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Activate Account') }}</p>
                        </div>

                        <div class="position-relative" style="min-height: 100px">

                            <!-- LOADER 2020-02-04 -->
                            <element-loading :active="loading"></element-loading>

                            <p class="text-success text-center mb-4" v-if="activateSuccess">{{ $t('Account Successfully Activated') }}</p>
                            <p class="text-danger text-center mb-4" v-if="activateFailed">{{ $t('Account Activation Failed') }}</p>
                            <p class="small text-center mb-0">
                                <router-link tag="button" class="btn btn-block btn-accent1" :to="{ name: 'login' }">{{ $t('Log In') }}</router-link>
                            </p>
                        </div>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AccountService } from '../common/services/api.service';
import CommonValidators from '../common/utils/commonValidators.utils';

export default {
    name: 'ActivateView',
    data () {
        return {
            activationKey: null,
            loading: true,
            activateSuccess: false,
            activateFailed: false
        };
    },
    beforeMount () {
        // Get activation key from url params.
        try {
            this.activationKey = this.$route.query.key;
        } catch (e) {
            this.activationKey = null;
        }

        // Validate and clean activation key.
        if (CommonValidators.validateString(this.activationKey)) {
            this.activationKey = this.activationKey.trim();
        } else {
            this.activationKey = null;
        }

        // If activation key is null, there is no need to make the request.
        if (this.activationKey == null) {
            // Stop loader.
            this.loading = false;
            this.activateSuccess = false;
            this.activateFailed = true;
        } else {
            // Make the request to zeus.
            AccountService.activateAccount(this.activationKey).then((data) => {
                this.activateSuccess = true; // Activation succeed.
                this.activateFailed = false;
            }).catch((reason) => {
                // console.warn(reason);
                this.activateSuccess = false;
                this.activateFailed = true; // Activation failed.
            }).finally(() => {
                // Stop loader.
                this.loading = false;
            });
        }
    }
};
</script>
